import React from 'react';
import styled, { css } from 'styled-components';
import media from '../utils/style';

import SEO from '../components/seo';
import Layout from '../components/layout';
import RevoligtAboutUs from "../components/aboutUs"
import PostItems from '../components/PostItems'



const Section = styled.div`
  text-align: center;
  padding-top: 45px;
  padding-bottom: 30px;
  ${(props) => props.dark
    && css`
      background: #292929;
      h2 {
        color: #fff;
      }
      h3 {
        color: #fff;
      }
      div {
        color: #979797;
      }
    `}
`;

const SectionTitle = styled.h4`
  font-size: 2em;
  margin: 0.67em 0;
  ${media.xs`
    font-size:1.5em;
  `}
`;


const IndexPage =  ({data}) => (
  <Layout>
    <SEO title="Revolight AB" />
    <Section id="us">
      <SectionTitle></SectionTitle>
      <RevoligtAboutUs />
    </Section>
    <Section>
    <PostItems />
    </Section>
    <Section></Section>
    <br/>
  </Layout>
);
export default IndexPage