import React from "react"
import { Container } from "react-bootstrap"


const RevoligtAboutUs = () => (
  <Container className="RevolightUs">
    <div>
      <h1 className="revolight-us">Welcome to Revolight Blog</h1>
    </div>
  </Container>

)

export default RevoligtAboutUs