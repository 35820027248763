import React from 'react';
import { useStaticQuery, graphql } from "gatsby"

import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/service.css';

import Img from 'gatsby-image';

const PostItems = () => {

const blogs = useStaticQuery(graphql`
      query allBlogJson {
        allBlogJson(sort: {fields: date, order: DESC}) {
					nodes {
						date(formatString: "dddd MMMM Do, YYYY")
						title
						slug
						icon
						tags
						image {
							childImageSharp {
								fixed(width: 350, height: 125) {
										...GatsbyImageSharpFixed
								}
							}
						}
					}
  		}
		}
    `
)

   return(
	  <section id="what-we-do">
    	<div className="container">
				{blogs.allBlogJson.nodes.map((blog, index) => 
					<div className="row mt-5" key={`content_item_${index}`}>
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" key={`div_${index}`}>
								<div className="card" key={`div1_${index}`}>
									<div className={`card-block ${blog.icon}`} key={`div-block_${index}`}>
										<h5 className="card-title" key={`h4_card_title_${index}`}><strong>{blog.title}</strong></h5>
									  <Img fixed={blog.image.childImageSharp.fixed}  alt="" key={`image_${index}`}/>
										<p className="card-text RevolightUs1" key={`p_card_text_${index}`} ></p>
										<a href={blog.slug} title="Read more" className="read-more" key={`a_read_more_${index}`}>Read more<i className="fa fa-angle-double-right ml-2"></i></a>
									</div>
									<br></br>
									<div class="card-footer"><i class="fas fa-pencil-alt"></i>       {blog.date} </div>
								</div>
							</div>
					</div>
      )}
			</div>
		</section> 
)}

export default PostItems;











